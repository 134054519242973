import ExtensibleError from './extensibleError';

export default class ServiceHttpResponseError extends ExtensibleError {
  constructor(message, response, additionalInfo = null) {
    super(message, additionalInfo);

    this.response = response;
  }

  toString() {
    return `${this.message} - ${this.response.status}: ${this.response.statusText}\n\t`;
  }
}
