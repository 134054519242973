import React, { Component } from 'react';
import DropZone from './DropZone';
import MiniDropZone from './MiniDropZone';
import PropTypes from 'prop-types';
import { uploadFile } from '../services/uploadService';

const STANDARD = 'standard';
const MINIMAL = 'minimal';

export default class UploadDropZone extends Component {
  static propTypes = {
    type: PropTypes.oneOf([STANDARD, MINIMAL]),
    children: PropTypes.node,
    onDrop: PropTypes.func,
    onUploadFileStart: PropTypes.func,
    onUploadFileError: PropTypes.func,
    onUploadFileSuccess: PropTypes.func,
    onDelete: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    uploadTenant: PropTypes.string,
    accessToken: PropTypes.string,
    provideThumbnails: PropTypes.bool,
    messages: PropTypes.shape({
      dropFilesHere: PropTypes.string,
      dragAndDropDocument: PropTypes.string,
      or: PropTypes.string,
      chooseFile: PropTypes.string,
    }),
    registerFileDialogOpen: PropTypes.func,
    deleteAfterDays: PropTypes.string,
  };

  static defaultProps = {
    type: STANDARD,
  };

  onDrop = async acceptedFiles => {
    const { 
      onUploadFileStart, 
      onUploadFileError, 
      onUploadFileSuccess, 
      uploadTenant, 
      accessToken, 
      provideThumbnails,
      deleteAfterDays
    } = this.props;
    if (onUploadFileStart) onUploadFileStart();
    const pendingAttachments = acceptedFiles.map(file =>
      uploadFile(file, accessToken, uploadTenant, provideThumbnails, deleteAfterDays)
    );
    try {
      const attachments = await Promise.all(pendingAttachments);
      onUploadFileSuccess(
        attachments.map(attachment => ({
          id: attachment.uploadId,
          name: attachment.uploadedFileName,
          url: attachment._links.self.href,
          type: attachment.originalFileContentType,
          size: attachment.originalFileSize,
        }))
      );
    } catch (error) {
      onUploadFileError(error);
    }
  };

  render() {
    return this.props.type === MINIMAL ? (
      <MiniDropZone
        onDrop={this.props.onDrop || this.onDrop}
        onFileDialogCancel={this.props.onFileDialogCancel}
        registerFileDialogOpen={this.props.registerFileDialogOpen}>
        {this.props.children}
      </MiniDropZone>
    ) : (
      <DropZone
        onDrop={this.props.onDrop || this.onDrop}
        onFileDialogCancel={this.props.onFileDialogCancel}
        registerFileDialogOpen={this.props.registerFileDialogOpen}
        messages={this.props.messages}
      />
    );
  }
}
