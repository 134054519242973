import i18n from 'i18next';

const de = require('../locales/de-DE/react-platform-settings.json');
const en = require('../locales/en-US/react-platform-settings.json');
const fr = require('../locales/fr-FR/react-platform-settings.json');
const hi = require('../locales/hi-IN/react-platform-settings.json');
const it = require('../locales/it-IT/react-platform-settings.json');
const ja = require('../locales/ja-JP/react-platform-settings.json');
const nl = require('../locales/nl-NL/react-platform-settings.json');
const pl = require('../locales/pl-PL/react-platform-settings.json');
const zh = require('../locales/zh-CN/react-platform-settings.json');

let i18nInstance = null;

function getI18nInstance() {
  if (!i18nInstance) {
    i18nInstance = i18n.createInstance();

    i18nInstance.init({
      fallbackLng: 'en',
      lng: 'en',
      resources: {
        de: { translations: de },
        en: { translations: en },
        fr: { translations: fr },
        hi: { translations: hi },
        it: { translations: it },
        ja: { translations: ja },
        nl: { translations: nl },
        pl: { translations: pl },
        zh: { translations: zh },
      },
      ns: ['translations'],
      defaultNS: 'translations',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    });
  }

  return i18nInstance;
}

export { getI18nInstance };
