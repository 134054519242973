import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { slate, alloy, ocean } from '@cimpress/react-components/lib/colors';
import IconDeliveryParachute from '@cimpress-technology/react-streamline-icons/lib/IconDeliveryParachute';
import IconUploadBottom from '@cimpress-technology/react-streamline-icons/lib/IconUploadBottom';

const UploadDropZone = props => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: props.onDrop,
    onFileDialogCancel: props.onFileDialogCancel,
  });

  useEffect(
    () => {
      if (props.registerFileDialogOpen) return props.registerFileDialogOpen(open);
    },
    [props.registerFileDialogOpen]
  );

  return (
    <div {...getRootProps()} className="well" style={dropZoneContainer}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <React.Fragment>
          <IconDeliveryParachute color={slate} size="3x" weight="light" />
          <div style={uploadTextMarginLeft}>{props.messages.dropFilesHere || 'Drop files here'}</div>
        </React.Fragment>
      ) : (
        <div style={uploadTextContainer}>
          <IconUploadBottom color={ocean.base} size="3x" weight="light" />
          <div style={marginLeft}>
            {props.messages.dragAndDropDocument || 'Drag and drop your file(s) in to this area to upload'}
          </div>
          <div style={orStyle}>&mdash; {props.messages.or || 'or'} &mdash;</div>
          <label className="btn btn-default">{props.messages.chooseFile || 'Choose File'}</label>
        </div>
      )}
    </div>
  );
};

UploadDropZone.propTypes = {
  messages: PropTypes.shape({
    dropFilesHere: PropTypes.string,
    dragAndDropDocument: PropTypes.string,
    or: PropTypes.string,
    chooseFile: PropTypes.string,
  }),
  onDrop: PropTypes.func.isRequired,
  onFileDialogCancel: PropTypes.func,
  registerFileDialogOpen: PropTypes.func,
};

UploadDropZone.defaultProps = {
  messages: {},
};

export default UploadDropZone;

const dropZoneContainer = {
  border: `2px dashed ${alloy}`,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '160px',
  width: '100%',
};

const orStyle = {
  color: slate,
  flexShrink: '0',
  margin: '0 15px',
  textTransform: 'uppercase',
};

const uploadText = {
  ...orStyle,
  fontWeight: 'bold',
  fontSize: '1.5em',
  textTransform: 'none',
};

const uploadTextContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: ocean.base,
};

const marginLeft = { marginLeft: '15px' };

const uploadTextMarginLeft = { ...uploadText, ...marginLeft };
