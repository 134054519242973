import ServiceHttpResponseError from './serviceHttpResponseError';
import { getBlob } from './serviceHelpers';

import endpoints from './endpoints';
const BASE_URL = endpoints.uploads;

export const uploadFile = async (file, accessToken, tenant, provideThumbnails, deleteAfterDays) => {
  const process = JSON.stringify(provideThumbnails ? { type: 'image' } : { type: 'storeOnly' });
  const deleteAfterDaysQuery = deleteAfterDays ? `&deleteAfterDays=${deleteAfterDays}` : '';
  const formData = new FormData();
  formData.append(file.name, file);
  const response = await fetch(
    `${BASE_URL}/v1/uploads/?process=${encodeURIComponent(process)}&tenant=${tenant}${deleteAfterDaysQuery}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
      mode: 'cors',
    }
  );

  if (response.status === 200) {
    return response.json().then(uploads => {
      const upload = uploads[0];
      return {
        ...upload,
        _links: {
          self: {
            href: `${BASE_URL}/v1/uploads/${upload.uploadId}?tenant=${upload.tenant}`,
          },
        },
      };
    });
  }

  const message = await response.json();
  throw new Error(`"${file.name}": ${message.Message}`);
};

export const getFile = (uploadId, tenant, accessToken) =>
  getBlob(`${BASE_URL}/v1/uploads/${uploadId}?tenant=${tenant}`);

export const getThumbnail = (uploadId, tenant, accessToken) =>
  getBlob(`${BASE_URL}/v1/uploads/${uploadId}/thumb?tenant=${tenant}`);

export const deleteUpload = (uploadId, tenant) => {
  const options = {
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
    method: 'DELETE',
    mode: 'cors',
  };

  return fetch(`${BASE_URL}/v1/uploads/${uploadId}?tenant=${tenant}`, options).then(response => {
    if (!response.ok) {
      throw new ServiceHttpResponseError(
        `Error getting blob from url ${BASE_URL}/v1/uploads/${uploadId}?tenant=${tenant}`,
        response
      );
    }
  });
};
