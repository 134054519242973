import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const MiniUploadDropZone = props => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: props.onDrop,
    onFileDialogCancel: props.onFileDialogCancel,
  });

  useEffect(
    () => {
      if (props.registerFileDialogOpen) return props.registerFileDialogOpen(open);
    },
    [props.registerFileDialogOpen]
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {props.children}
    </div>
  );
};

MiniUploadDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onFileDialogCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  registerFileDialogOpen: PropTypes.func,
};

export default MiniUploadDropZone;
