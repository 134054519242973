import React, { useState, Fragment, useEffect } from 'react';
import { Modal, colors } from '@cimpress/react-components';
import ThumbnailImage from './ThumbnailImage';
import PropTypes from 'prop-types';
import IconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconRemoveCircle';
import { getBlob } from '../services/serviceHelpers';
import { getFile, getThumbnail } from '../services/uploadService';

const iconContainer = {
  position: 'absolute',
  top: '-16px',
  right: '-16px',
  padding: '8px',
  transition: 'transform 100ms ease-in-out, color 100ms ease-in-out',
  cursor: 'pointer',
};

const btnFlex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const thumbnail = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  overflow: 'hidden',
};

function noop() {}

const UploadThumbnail = ({
  name,
  contentType,
  uploadId,
  url,
  onDelete,
  disableModal,
  messages,
  size,
  tenant,
  accessToken,
}) => {
  const [thumbnailMeta, setThumbnailMeta] = useState({ thumbUrl: '', thumbContentType: '', downloadedFileUrl: '' });
  const [showModal, setShowModal] = useState(false);

  useEffect(
    () => {
      // If all we have is a url, we avoid reverse engineering a thumbnail
      const thumbnailPromise = !url && uploadId && getThumbnail(uploadId, tenant, accessToken);
      const fullImagePromise = url ? getBlob(url, accessToken) : getFile(uploadId, tenant, accessToken);

      Promise.all([thumbnailPromise, fullImagePromise])
        .then(([thumb, file]) => {
          const thumbUrl = thumb && URL.createObjectURL(thumb);
          const downloadedFileUrl = file && URL.createObjectURL(file);
          setThumbnailMeta({ thumbUrl, thumbContentType: thumb && thumb.type, downloadedFileUrl });
        })
        .catch(() => setThumbnailMeta({ thumbUrl: '', thumbContentType: '', downloadedFileUrl: '' }));
    },
    [uploadId, url]
  );

  const { thumbUrl, thumbContentType, downloadedFileUrl } = thumbnailMeta;

  return (
    <Fragment>
      <div style={{ width: size, height: size }}>
        {!onDelete ? null : (
          <div style={{ position: 'relative' }}>
            <div style={iconContainer}>
              <div
                onClick={() => onDelete(uploadId)}
                style={{
                  height: '16px',
                  width: '16px',
                  borderRadius: '100%',
                  backgroundColor: 'white',
                }}>
                <IconRemoveCircle color={colors.ocean.base} size="lg" weight="fill" />
              </div>
            </div>
          </div>
        )}
        <div
          style={thumbnail}
          className={disableModal ? '' : 'clickable'}
          onClick={disableModal ? undefined : () => setShowModal(true)}>
          <ThumbnailImage
            scale={true}
            url={thumbUrl || downloadedFileUrl}
            contentType={thumbContentType || contentType}
          />
          {name && <div style={{ padding: '5px' }}>{name}</div>}
        </div>
      </div>
      <Modal
        show={showModal}
        onRequestHide={() => setShowModal(false)}
        title={name}
        closeButton={true}
        closeOnOutsideClick={true}
        footer={
          <div style={btnFlex}>
            <a
              href={downloadedFileUrl}
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn-secondary"
              download={name}>
              {messages.download}
            </a>
            <button className="btn btn-default" onClick={() => setShowModal(false)}>
              {messages.close}
            </button>
          </div>
        }>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ThumbnailImage scale={false} url={downloadedFileUrl} contentType={contentType} />
        </div>
      </Modal>
    </Fragment>
  );
};

UploadThumbnail.propTypes = {
  size: PropTypes.string,
  disableModal: PropTypes.bool,
  onDelete: PropTypes.func,
  uploadId: PropTypes.string,
  url: PropTypes.string,
  contentType: PropTypes.string,
};

UploadThumbnail.defaultProps = {
  size: '100px',
  onDelete: noop,
  messages: {
    download: 'Download',
    close: 'Close',
  },
};

export default UploadThumbnail;
