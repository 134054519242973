import React from 'react';
import { alloy, silver } from '@cimpress/react-components/lib/colors';
import UploadThumbnail from './UploadThumbnail';

export const ThumbnailDisplay = ({ uploads, placeholderText, onDelete, tenant, accessToken }) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '1em',
        flex: '1 0 100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: `2px dashed ${alloy}`,
        borderRadius: '2px',
        backgroundColor: silver,
        borderTop: 'none',
        flexWrap: 'wrap',
      }}>
      {uploads.length ? (
        uploads.map(({ id, href, contentType, description }) => (
          <div key={id} style={{ padding: '10px' }}>
            <UploadThumbnail
              uploadId={id}
              url={href}
              contentType={contentType}
              name={description}
              onDelete={onDelete}
              tenant={tenant}
              accessToken={accessToken}
            />
          </div>
        ))
      ) : (
        <span className="text-muted">{placeholderText}</span>
      )}
    </div>
  );
};

ThumbnailDisplay.defaultProps = {
  placeholderText: 'Any files will display here',
  uploads: [],
};
