import React, { PureComponent } from 'react';
import IconPicturePolaroidLandscape from '@cimpress-technology/react-streamline-icons/lib/IconPicturePolaroidLandscape';
import PropTypes from 'prop-types';

const imageRegex = /image\/.*/;

export default class ImageDisplay extends PureComponent {
  static propTypes = {
    url: PropTypes.string,
    contentType: PropTypes.string,
  };

  static defaultProps = {
    contentType: 'image/',
  };

  render() {
    const { url, contentType } = this.props;
    if (url && contentType && contentType.match(imageRegex)) {
      return <img style={{ maxWidth: '100%' }} alt={''} src={url} />;
    }

    return <IconPicturePolaroidLandscape size="5x" />;
  }
}
